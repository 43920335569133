// ontact style here
// 
.contact-page-form{
    .single-input{
        position: relative;
        i{
            position: absolute;
            left: 15px;
            top: 58px;
        }
    }
    label{
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        color: #083A5E;
        margin-bottom: 15px;
    }
    input{
        height: 50px;
        border: 1px solid #E2E2FF;
        border-radius: 4px;
        padding: 10px 15px 10px 40px;
    }
    textarea{
        height: 115px;
        border: 1px solid #E2E2FF;
        border-radius: 4px;
        padding: 10px 15px 10px 40px;
        &:focus{
            border-color: var(--color-primary);
        }
    }
    .single-input{
        margin: 20px 0;
    }
    button{
        border-radius: 4px;
    }
}


.single-card-area-wrapper{
    padding: 33px 15px;
    text-align: center;
    border: 1px solid #E2E2FF;
    border-radius: 10px;
    transition: .3s;
    &:hover{
        border-color: var(--color-primary);
    }
    a.rts-btn{
        border-radius: 4px;
        padding: 10px 25px;
        display: flex;
        align-items: center;  
        max-width: max-content;
        margin: auto;
        border: 1px solid var(--color-primary);
        background: transparent;
        color: var(--color-primary);
        transition: .3s;
        i{
            margin-left: 10px;
            transition: .3s;
        }  
        &:hover{
            background: var(--color-primary);
            color: #fff;
            i{
                color: #fff;
            }
        }
    }
}

.single-form-s-wrapper{
    max-width: 410px;
    margin: auto;
    text-align: center;
    .head{
        span{
            color: #3F3EED;
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
        }
        .title{
            font-weight: 600;
            font-size: 24px;
            line-height: 34px;
        }
    }
    .body{
        input{
            height: 50px;
            border: 1px solid #E2E2FF;
            border-radius: 4px;
            margin: 10px 0;
            &:focus{
                border-color: var(--color-primary);
            }
        }
        .check-wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;
            a{
                color: var(--color-primary) !important;
            }
            label{
                font-weight: 400;
                font-size: 16px;
            }
        }
        button{
            border-radius: 4px;
            margin-top: 25px;
        }
        p{
            margin-top: 20px;
            margin-bottom: 0;
            display: flex;
            justify-content: center;
            a{
                color: var(--color-primary);
            }
        }
    }
}
.other-separator{
    position: relative;
    margin-top: 30px;
    &::after{
        position: absolute;
        content: '';
        left: 0;
        top: 50%;
        height: 1px;
        width: 180px;
        background: #E2E2FF;
    }
    &::before{
        position: absolute;
        content: '';
        right: 0;
        top: 50%;
        height: 1px;
        width: 180px;
        background: #E2E2FF;
    }
    span{
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        color: #083A5E;
    }
}

.sign-in-otherway{
    margin-top: 45px;
    .single{
        display: flex;
        align-items: center;
        padding: 15px 30px;
        text-align: center;
        justify-content: center;
        border: 1px solid #E2E2FF;
        border-radius: 4px;
        margin-top: 20px;
        cursor: pointer;
        .icon{
            margin-right: 10px;
        }
        p{
            margin-bottom: 0;
            position: relative;
            margin-bottom: -4px;
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            color: #083A5E;
        }
    }
}

input[type=checkbox] ~ label::before, input[type=radio] ~ label::before {
    content: " ";
    position: absolute;
    top: 1 px;
    left: 0;
    width: 15px;
    height: 15px;
    background-color: #ffffff;
    border-radius: 2px;
    transition: all 0.3s;
    border-radius: 2px;
    border: 1px solid #E2E2FF;
}
input[type=checkbox] ~ label::after, input[type=radio] ~ label::after {
    content: " ";
    position: absolute;
    top: 16%;
    left: 2px;
    width: 10px;
    height: 6px;
    background-color: transparent;
    border-bottom: 2px solid var(--color-primary);
    border-left: 2px solid var(--color-primary);
    border-radius: 2px;
    transform: rotate(-45deg);
    opacity: 0;
    transition: all 0.3s;
}




#form-messages{
    .success{
        color: var(--color-primary);
    }
    .error{
        color: var(--color-danger);
    }
}