
/**
* Bootstrap overwrite
* comment out line @include _assert-starts-at-zero($grid-breakpoints);
* File must be load before other files
*/
$grid-breakpoints: (
        xs5: 0,
        xs4: 320px,
        xs3: 360px,
        xs2: 400px,
        xs: 767px,
        sm: 1023px,
        md: 1199px,
        lg: 1599px,
        lg2:1919px,
        xl: 2499px,
        xxl: 3839px
);

$container-max-widths: (
        xs5:  0,
        xs4:  320px,
        xs3:  360px,
        xs2:  400px,
        xs:   720px,
        sm:   1000px,
        md:   1140px,
        lg:   1200px,
        lg2:  1400px,
        xl:   1600px,
        xxl:  3400px
);

$colorHeadline : #141414;
$colorFooter: #313030;
$colorHeader: #313030;

//überschrieb pagination
$pagination-active-color:           #ffffff !default;
$pagination-active-bg:              $colorHeader !default;
$pagination-active-border-color:    $colorHeader !default;
$pagination-color:                  $colorHeader !default;
$pagination-bg:                     #ffffff !default;







/////$font-family-sans-serif:   -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

//roboto pfad überschreiben, siehe roboto pfad --> mixins
/////$roboto-font-path: '../fonts/';
