
// feature style here
.single-feature-area-start{
    display: flex;
    align-items: center;
    padding: 86px 70px;
    background: #F2F2FF;
    justify-content: space-between;
    gap: 70px;
    margin-bottom: 60px;
    border-radius: 10px;
    &.index-feature{
        @media #{$large-mobile} {
            padding: 15px;
            padding-bottom: 40px;
        }
        .image-area{
            //max-width: max-content;
            //min-width: max-content;
            @media #{$smlg-device} {
                min-width: 350px;
            }
            @media #{$md-layout} {
                min-width: auto;
            }
            @media #{$sm-layout} {
                min-width: auto;
            }
        }
        .feature-content-area p.disc,.feature-content-area div.disc{
            width: 100%;
            margin-bottom: 1em;
        }
    }
    @media #{$laptop-device} {
        gap: 50px;
    }
    @media #{$smlg-device} {
        padding: 30px;
        gap: 30px;
    }
    @media #{$md-layout} {
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
    }
    @media #{$sm-layout} {
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
    }
    &.bg-red-l{
        background: #FFF2F2;
        .featue-content-area{
            span.pre{
                -webkit-text-stroke-color: #FFC6C6;
                color: #FFF2F2;
            }
        }
    }
    &.bg-blue-l{
        background: #F2FAFF;
        .featue-content-area{
            span.pre{
                -webkit-text-stroke-color: #C0E7FF;
                color: #F2FAFF;
            }
        }
    }
    &:last-child{
        margin-bottom: 0;
    }
    .image-area{
        max-width: 650px;
        //max-width: max-content;
        //min-width: max-content;
        @media #{$smlg-device} {
            min-width: 350px;
        }

    }
    .featue-content-area{
        span.pre{
            -webkit-text-stroke-width: .5px;
            -webkit-text-stroke-color: #BCBCFF;
            font-weight: 700;
            font-size: 100px;
            line-height: 110px;
            color: #F2F2FF;
        }
        .title{
            margin-bottom: 20px;
            @media #{$laptop-device} {
                font-size: 36px;
            }
        }
        p.disc{
            margin-bottom: 50px;
            width: 90%;
        }
    }
}

.title-left-feature{
    .title{
        font-weight: 600;
        font-size: 48px;
        line-height: 58px;
        color: #083A5E;
    }
    p.disc{
        width: 65%;
    }
}

.single-feature-area-main-inner{
    padding: 80px;
    background: #F6F6F6;
    border-radius: 10px;
    margin-bottom: 30px;
    &:last-child{
        margin-bottom: 0;
    }
    .feature-content-inner{
        .title{
            font-weight: 600;
            font-size: 34px;
            line-height: 44px;
            color: #083A5E;
            span{
                color: var(--color-primary);
            }
        }
        p.disc{
            width: 70%;
        }
    }
    .thumbnail-feature{
        padding: 12px;
        border-radius: 10px;
        border: 1px solid #BCBCFF;
        background: #F6F6F6;
        img{
            width: 100%;
        }
    }
}


.rts-feature-area.home-six{
    background: #0C1221;
    .rts-feature-area-inner{
        border-radius: 10px;
        border: 1px solid #121B32;
        background: #0E1424;
        padding: 40px;
        .left-side-content{
            margin-right: 100px;
            @media(max-width:991px){
                margin-right: 0;
                margin-bottom: 80px;
            }
            .title{
                color: #FFFFFF;
                margin-bottom: 25px;
            }
            .disc{
                color: #FFFFFF;
                margin-bottom: 60px;
            }
            .rts-btn{
                border-radius: 4px;
            }
            &.area-2{
                margin-left: 50px;
                margin-right: 0;
                @media(max-width:991px){
                    margin-left: 0;
                    margin-top: 80px;
                }
            }
        }
        .right-side-image{
            text-align: center;
        }
    }
}
.rts-isotope-area{
    background: #0C1221;
    .rts-isotope-area-inner{
        margin: 0 80px;
        @media(max-width:576px){
            margin: 0 20px;
        }
    }
    .rts-heading{
        .title-inner{
            .title{
                color: #FFFFFF;
                text-transform: capitalize;
            }
            .disc{
                color: #FFFFFF;
                margin-bottom: 50px;
                @media(max-width:650px){
                    br{
                        display: none;
                    }
                }
            }
        }
    }
    .portfolio-menu{
        display: flex;
        max-width: 470px;
        margin: 0 auto 50px auto;
        button{
            color: #FFFFFF;
            background: none;
            padding: 5px 10px ;
            border-radius: 4px;
            &.active{
                background: #3F3EED;
            }
        }
    }
    .grid{
        .grid-item{
            .portfolio-wrapper2{
                margin:0 10px 30px  10px;
                border-radius: 10px;
                overflow: hidden;
                position: relative;
                transition: all .4s;
                &:hover{
                    .portfolio-text{
                        transform: translateY(0);
                        opacity: 1;
                    }
                    .img-fluid{
                        transform: scale(105%);
                    }
                }
                .img-fluid{
                    transform: scale(100%);
                    transition: all .4s;
                    width: 100%;
                }
                .portfolio-text{
                    position: absolute;
                    bottom: 40px;
                    left: 40px;
                    transform: translateY(50px);
                    opacity: 0;
                    transition: all .4s;
                    .text{
                        .p-title{
                            color: #121B32;
                            text-align: center;
                            font-size: 32px;
                            font-weight: 700;
                            line-height: 26px;
                            background: #FFFFFF;
                            border-radius: 4px;
                            padding: 10px 32px;
                        }
                    }
                }
            }
        }
    }
}

.mfp-image-holder .mfp-content{
    width: 450px;
    height: 450px;
}

.mfp-gallery .mfp-image-holder .mfp-figure{
    width: 100%;
    height: 100%;
    figure{
        width: 100%;
        height: 100%;
        img{
            width: 100%;
        }
    }
}
.mfp-bottom-bar{
    position: unset;
    margin-top: 0;
    display: flex;
    justify-content: space-between;
    .mfp-counter{
        position: unset;
    }
}

.rts-project-area{
    background: #0E1424;
    .title{
        color: #FFF;
        font-size: 24px;
        font-weight: 600;
        line-height: 38px;
        letter-spacing: -0.48px;
        text-transform: capitalize;
        margin-bottom: 40px;
    }
    .rts-project-area-inner{
        margin: 0 80px;
        @media(max-width:576px){
            margin: 0 20px;
        }
        @media(max-width:400px){
            margin: 0 10px;
        }
        .image-area{
            margin: 0 15px;
            position: relative;
            z-index: 1;
            @media(max-width:1200px){
                margin: 15px;
            }
            &:hover{
                &::after{
                    opacity: 1;
                }
                .content{
                    span{
                        a{
                            i{
                                opacity: 1;
                            }
                        }
                    }
                }
            }
            &::after{
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background: rgba(18, 27, 50, 0.60);
                left: 0;
                top: 0;
                opacity: 0;
                transition: all .3s;

            }
            img{
                width: 100%;
            }
            .content{
                position: absolute;
                width: 85%;
                display: flex;
                justify-content: space-between;
                bottom: 20px;
                left: 50%;
                transform: translateX(-50%);
                z-index: 1;
                span{
                    a{
                        color: #FFFFFF;
                        i{
                            opacity: 0;
                            transition: all .3s;
                        }
                    }
                }
            }
        }
    }
}
.rts-popular-image-area{
    background: #0C1221;
    .title{
        color: #FFF;
        font-size: 24px;
        font-weight: 600;
        line-height: 38px;
        letter-spacing: -0.48px;
        text-transform: capitalize;
        margin-bottom: 40px;
    }
    .rts-popular-image-area-inner{
        margin: 0 80px;
        @media(max-width:576px){
            margin: 0 20px;
        }
        @media(max-width:400px){
            margin: 0 10px;
        }
    }
    .image-area{
        position: relative;
        margin: 15px;
        overflow: hidden;
        transition: all .3s;
        &:hover{
            .action-button{
                right: 20px;
            }
            .content{
                .bottom-wrapper{
                    .price{
                        transform: translateY(-30px);
                    }
                    .add-to-cart{
                        transform: translateY(-25px);
                    }
                }
            }
        }
        .image{
            width: 100%;
            a{
                display: block;
                width: 100%;
                height: 100%;
                position: relative;
                z-index: 1;
                &::after{
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background: rgba(18, 27, 50, 0.30);
                }
            }
        }
        img{
            width: 100%;
            position: relative;
        }
        .crown-tag{
            position: absolute;
            background: rgba(18, 27, 50, 0.3);
            width: 40px;
            height: 40px;
            line-height: 35px;
            display: block;
            border-radius: 50%;
            text-align: center;
            left: 20px;
            top: 20px;
            z-index: 1;
            img{
                width: auto;
            }
        }
        .action-button{
            padding: 0;
            margin: 0;
            position: absolute;
            top: 15px;
            right: -50px;
            transition: all .3s;
            z-index: 1;

            .single-action{
                list-style: none;
                background: #FFFFFF;
                width: 40px;
                height: 40px;
                line-height: 41px;
                border-radius: 4px;
                display: block;
                text-align: center;
                margin-bottom: 15px;
                a{
                    transition: all .3s;
                    display: block;
                    width: 100%;
                    border-radius: 4px;
                    i{
                        color: #121B32;
                    }
                    &:hover{
                        background: #151f39;
                        i{
                            color: #ffffff;
                        }
                    }
                }
            }
        }
        .content{
            margin-top: 30px;
            span{
                a{
                    color: #FFF;
                    text-align: center;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 26px;
                    letter-spacing: -0.4px;
                    transition: all .3s;
                    &:hover{
                        color: var(--color-primary);
                    }
                }
            }
            .bottom-wrapper{
                margin-top: 10px;
                overflow: hidden;
                .price,
                .add-to-cart{
                    color: #D9D9D9;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 26px;
                    letter-spacing: -0.32px;
                    transition: all .3s;
                }
                .add-to-cart{
                    transform: translateY(30px);
                    display: block;
                }
            }
        }
    }
    .button-area{
        margin-top: 50px;
        .rts-btn{
            border-radius: 5px;
        }
    }
}
