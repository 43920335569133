
//h1,h2,h3,h4,h5,h6 {
//  a {
//    text-decoration: none;
//    color: $colorHeadline;
//  }
//  color: $colorHeadline;
//}

.img-fluid {
  max-width: 100%;
  height: auto;
  width: 100%;
}


p {
  a {
    text-decoration: underline;
  }
}
div {
  a {
    text-decoration: underline;
  }
}

u {
    i {
        font-size: 0.8em;
        color: lightgrey;
        margin-right: 0 !important;
    }
}

img{
  &.logo {
    @include media-breakpoint-between(xs5,xs){
       // width: 120px;
        display: none;
    }
    //720-1500
    @include media-breakpoint-between(xs,lg){
      width: 170px;
    }
    //1500-2200
    @include media-breakpoint-between(lg,xl){
      width: 170px;
    }
    //ab 2200
    @include media-breakpoint-up(xl){
      width: 200px;
    }
    @include media-breakpoint-up(xxl){
      width: 300px;
    }
  }
}

//.text-align-center {
//  text-align: center;
//}

#dataToSystem{
  .pricing-style-one {
    @include media-breakpoint-up(md){
       min-height: 610px;
    }
  }
}

#prices{
  .pricing-style-one {
    @include media-breakpoint-up(md){
      min-height: 770px;
    }
  }
}

.additionalAsk {
    @include media-breakpoint-between(xs5,xs){
        display: none;
    }
}

#bodyPageTerms {
    h3 {
        line-height: 0;
        margin-top: 60px;
    }
}
