.tooltip {
    font-size: 1em;

    .tooltip-inner {
        @include media-breakpoint-down(xs2) {
            max-width: 300px;
            width: 300px;
        }
        @include media-breakpoint-up(md) {
            max-width: 600px;
            width: 600px;
        }
        @include media-breakpoint-up(xxl) {
            max-width: 800px;
            width: 800px;
        }
    }
}

