/*for FireFox*/
input[type="submit"]::-moz-focus-inner, input[type="button"]::-moz-focus-inner
{
  border : 0px;
}
/*for IE8 */
input[type="submit"]:focus, input[type="button"]:focus
{
  outline : none;
}
a, a:visited, a:focus, a:active, a:hover{
  outline:0 none !important;
}

:focus {outline:none;}
::-moz-focus-inner {border:0;}


/* Partielle Liste verfügbarer Werte in Gecko */
/* -moz-appearance: none;*/


/* Partielle Liste verfügbarer Werte in WebKit/Blink */
/* -webkit-appearance: none;*/
