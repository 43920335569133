
#header-wrapper {
    display: unset; //work around wegen mmenu-js,  https://stackoverflow.com/questions/43707076/how-does-the-position-sticky-property-work
}

header {
    position: fixed;
    top: 8px; //indicator height
    min-height: 40px;
    width: 100%;
    box-shadow: 0px 7px 18px #1810100d;
    background: #151f39;
    border-bottom: 1px solid #bdbdbd;
    color: white;
    z-index: 9999;
    padding-top: 4px;
    padding-bottom: 4px;


    .header-menu-and-search {
        margin-left: 10px;
        margin-right: 4px;

        .inner-header {
            position: relative;
            display: flex;
            align-items: center;


            .header-menu {
                padding-right: 0.5em;

                a {
                    @include media-breakpoint-down(xs) {
                        font-size: 1.6em;
                    }
                    @include media-breakpoint-up(xs) {
                        font-size: 2.6em;
                    }
                    color: white;
                    text-decoration: none;
                    min-width: 26px;
                }

                i {
                    @include media-breakpoint-down(xs) {
                        font-size: 1.4em;
                    }
                    @include media-breakpoint-up(xs) {
                        font-size: 1.2em;
                    }
                    color: white;
                    text-decoration: none;
                    min-width: 26px;
                }
            }

            .header-hint-text {
                @include media-breakpoint-down(xs) {
                    display: none;
                }
                margin-left: 0.5em;
                margin-right: 0.5em;
            }

            .header-search {
                flex-grow: 1;
                @include media-breakpoint-down(xs) {
                    margin-left: 0.5em;
                }

                input.input-search {
                    width: 100%;
                    @include media-breakpoint-down(xs) {
                        //width:280px;
                    }
                    @include media-breakpoint-down(xs2) {
                        //width:180px;
                    }

                }
            }

            .header-logo {
                //float: left;
                //display: flex;
                img {
                    @include media-breakpoint-up(xs) {
                        width: 120px;
                    }
                    @include media-breakpoint-up(xl) {
                        width: 180px;
                    }
                    @include media-breakpoint-down(xs) {
                        width: 100px;
                    }
                }
            }


        }
        .header-spoken-partner {
            margin-left: 1em;
            @include media-breakpoint-down(xs) {
                margin-left: 2em;
                font-size: 0.69em;
                line-height: 1.5em;
            }
        }
    }
}

#content-wrapper {
    background-color: white;
    //background-image: url(../img/background.jpg);
    //background-size: cover;
    //-moz-transition: opacity 0.25s ease-in-out;
    //-webkit-transition: opacity 0.25s ease-in-out;
    //-ms-transition: opacity 0.25s ease-in-out;
    //transition: opacity 0.25s ease-in-out;
    #content-wrapper-inner {
        position: relative;
        //siehe .search-filter-content
        @include media-breakpoint-down(xs) {
            //top: 3.5em;
        }
        @include media-breakpoint-up(xs) {
            top: 2.5em;
        }
    }
}
