
#footer-wrapper {
    margin-top: 2.5em;
    footer {
        background-color: $colorHeader;
        color: white;
        margin-top: 2.5em;
        padding: 0.5em;
        @include media-breakpoint-down(xs) {
            padding-top: 1.5em;
        }

        .logo {
            height: 60px;
            @include media-breakpoint-up(md) {
                height: 80px;
            }
        }

        p {
            margin: 0;
        }

        #copyright {
            ul {
                display: flex;
                align-items: center;
                list-style-type: none;

                li {
                    padding-right: 1em;
                }
            }
        }
    }
}
